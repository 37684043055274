<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'

defineProps({
  title: {
    type: String,
    required: true,
  },
  textYes: {
    type: String,
    default: null,
  },
  textNo: {
    type: String,
    default: null,
  },
})

const emits = defineEmits<{(e: 'update:confirmation', value: boolean): void }>()

const isOpen = ref(false)

const confirm = (value: boolean) => {
  isOpen.value = false
  emits('update:confirmation', value)
}

onMounted(() => {
  nextTick().then(() => {
    isOpen.value = true
  })
})
</script>
<template>
  <modal-or-bottom-sheet
    v-model="isOpen"
    :drawer-props="{hideHeader: false, bodyClass: 'pb-4'}"
    :centered="false"
    :title="title"
    footer-class="justify-center"
    header-class="text-center w-full"
    :closable="false"
    hide-body
  >
    <slot />
    <template #footer>
      <slot name="buttons">
        <button
          class="text-neutral-900 hover:text-primary active:text-primary-dark disabled:text-neutral-150 px-4 h-[38px] leading-4 rounded-full border-2 border-neutral-150 active:border-primary-dark hover:border-primary disabled:border-neutral-150 font-semibold whitespace-nowrap"
          @click="confirm(true)"
        >
          {{ textYes }}
        </button>
        <button
          class="text-primary hover:text-white active:text-white disabled:text-neutral-150 px-4 h-[38px] leading-4 bg-primary-light hover:bg-primary active:bg-primary-dark disabled:bg-neutral-100 rounded-full font-semibold whitespace-nowrap"
          @click="confirm(false)"
        >
          {{ textNo }}
        </button>
      </slot>
    </template>
  </modal-or-bottom-sheet>
</template>
