// eslint-disable-next-line import/no-extraneous-dependencies
import { createApp } from 'vue'
import type { App } from 'vue'
import Confirm from '~/components/common/Confirm.vue'

type CreateArgs = {title: string, textYes?: string, textNo?: string, defaultSlot?: VNode}

function confirmationModal() {
  const { t } = useI18n()

  const confirmApp = ref<App | null>(null)
  const confirmation = ref<boolean | null>(null)
  const divElement = ref<HTMLDivElement | null>(null)

  const create = ({ title, textYes, textNo, defaultSlot }: CreateArgs): Promise<boolean> => {
    if (confirmApp.value === null) {
      const element = document.createElement('div')
      divElement.value = element
      document.body.prepend(element)
      confirmApp.value = createApp(
        h(
          Confirm,
          {
            title,
            textYes: textYes || t('common.yes'),
            textNo: textNo || t('common.no'),
            'onUpdate:confirmation': onUpdateConfirmation,
          },
          () => defaultSlot || '',
        ),
      )
      confirmApp.value?.mount(element)
    }
    return new Promise(resolve => {
      const watcher = watch(confirmation, value => {
        if (value !== null) {
          resolve(value)
          watcher()
        }
      })
    })
  }

  const onUpdateConfirmation = (value: boolean) => {
    confirmation.value = value
    nextTick().then(() => {
      destroy()
    })
  }

  const destroy = () => {
    if (confirmApp.value !== null) {
      confirmApp.value?.unmount()
      confirmApp.value = null
      confirmation.value = null
      divElement.value && divElement.value.remove()
      divElement.value = null
    }
  }

  return create
}

export default confirmationModal
